import { IconType } from "react-icons";
import { MdOutlineDraw, MdArchitecture } from "react-icons/md";
import { HiOutlineDocumentDuplicate } from "react-icons/hi2";

import { ReactNode, useEffect, useState } from "react";

export interface Category {
    ref: string;
    name: string;
    logo: IconType;
    subcategories: Subcategory[];
}

export interface Subcategory {
    ref: string;
    name: string;
}

export interface Image {
    id: number;
    title: string;
    file_format: string;
    user_id: number;
    // Optionally include additional fields as needed
  }

export interface Changelog {
    id: number;
    date: string;
    title: string;
    version: string;
    content: string;
    image_url?: string;

}
export const categories: Category[] = [
    {
        ref: "free_drawing",
        name: "Freedrawing",
        logo: MdOutlineDraw,
        subcategories: [
            {
                ref: "free_drawing",
                name: "Open canvas",
            },
            {
                ref: "semantic_drawing",
                name: "Semantic canvas",
            }
        ]
    },
    {
        ref: "architecture",
        name: "Architecture",
        logo: MdArchitecture,
        subcategories: [
            {
                ref: "sketch_to_render",
                name: "Render your sketch"
            },
            {
                ref: "free_drawing_architecture",
                name: "Free doodling"
            },
            {
                ref: "semantic_drawing_architecture",
                name: "Semantic architecture"
            }
        ]
    },
    {
        ref: "documents",
        name: "Saved work",
        logo: HiOutlineDocumentDuplicate,
        subcategories: []
    }
    /*
    "free_drawing",
    "doodling",
    "architecture"*/
]