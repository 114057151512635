import "./Aboutpage.css"
import gsap from "gsap";
import { useEffect, useRef } from "react";



export const Aboutpage = ({title}:{title: string}) => {
    const paraRefs = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(()=>{
        gsap.from('.about-title-wrapper', {
          duration: 1,
          scale: 0.5,
          opacity: 0,
          ease: 'power3.out',
        })
      }, [])

    return (

        <div className="aboutpage-wrapper">
            <div className="aboutpage-title-wrapper">
                <p className="page-title">About</p>
                <p className="page-subtitle">About this project, it's motivation and future plans.</p>
            </div>
            <div className="aboutpage-content">
                <div className="about-title-wrapper">
                    <p className="about-shadow-title">Built to inspire</p>
                    <p className="pagecontent-title">Built to inspire</p>
                </div>
                
                <p className="about-content" ref={el => (paraRefs.current[0] = el)}>Born from a passion to enhance artistic creativity, this project began as a tool to transform architectural sketches into lifelike digital images. It has since expanded to include various modes tailored for diverse creative needs. Although still in its developmental stages with some tools under construction, updates and enhancements are continually being made—please refer to my changelog for the latest updates. This application was developed within an ambitious four-week timeframe.</p>
                <p className="about-content aboutpage-info-box" ref={el => (paraRefs.current[1] = el)}>Currently, the project is designated for private use. Those interested in exploring or utilizing its capabilities can request access by contacting draglandoliver@gmail.com. For educational and private use only.</p>
                <p className="about-content" ref={el => (paraRefs.current[2] = el)}>Initially powered by a GPU-based cloud instance, the project has transitioned to using CPUs due to the high costs associated with GPU utilization. This change is significant as it increases the time required to generate images but allows for more sustainable development and operational costs. The use of CPUs will persist until the project is ready for commercialization. Despite this, my primary focus remains on completing my bachelor's thesis, therefore resulting in a decrease in progress of the project's development.</p>
            </div>
        </div>
    )
}
export default Aboutpage