import Login from "./login/Login"
import Register from "./register/Register"

import "./Loginpage.css"
import { useState, useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"

export const Loginpage = ({title}:{title: string}) => {

    useEffect(() => {
        document.title = title;
    }, [title]);

    const [useLogin, setUseLogin] = useState<boolean>(true)
    const navigate = useNavigate()
    return (
        <div className="login-page">
            <Link to="/" className="login-page-title">Beyourownartist.ai</Link>
            <div className="vertical-divider"></div>
            {useLogin ? (
                <div>
                    <Login />
                    <p className="toggle-info">
                        No account?
                        <span
                            className="toggle-login"
                            onClick={(e) => setUseLogin(!useLogin)}
                        > Register </span>
                        instead.
                    </p>
                </div>
            ) : (
                <div>
                    <Register />
                    <p className="toggle-info">
                        Have an account?
                        <span
                            className="toggle-login"
                            onClick={(e) => setUseLogin(!useLogin)}
                        > Login </span>
                        instead.
                    </p>
                </div>
            )}
        </div>
    )
}
export default Loginpage