import React from 'react';
import './App.css';

import LandingPage from './pages/landingpage/Landingpage';
import Kittelsenpage from './pages/kittelsen/kittelsenpage';
import Loginpage from './pages/auth/Loginpage';
import Changelogpage from './pages/changelog/Changelogpage';
import Aboutpage from './pages/about/Aboutpage';

import Footer from './components/footer/Footer';
import Navbar from './components/navbar/Navbar';
import { Route, Routes, useLocation } from "react-router-dom";

import axios from 'axios';

export const getCsrfToken = async () => {
  try {
    const response = await axios.get('/csrf', {
      withCredentials: true // Include credentials in the request
    });

    return response.data.token // Return the CSRF token
  } catch (error) {
    console.error('Error occurred while fetching CSRF token:', error);
    return null;
  }
}

function App() {

  const location = useLocation();

  // Check if the current route is "/auth"
  const isAuthRoute = location.pathname === '/auth';

  return (
    <div className="App">
      {!isAuthRoute && <Navbar />}
      <Routes>
        <Route path="/" element={<LandingPage title="Home"/>} />
        <Route path="/kittelsen" element={<Kittelsenpage title="Kittelsen"/>} />
        <Route path="/auth" element={<Loginpage title='Auth'/>} />
        <Route path="/changelog" element={<Changelogpage title='Changelog'/>} />
        <Route path="/about" element={<Aboutpage title='About'/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;