import React from 'react';
import "./BrushTool.css"
interface BrushToolProps {
  onChangeBrushSize: (brushSize: number) => void;
  brushSize: number;
  selectedColor: string
  className?: string
}

export const BrushTool = ({ onChangeBrushSize, brushSize, selectedColor, className}: BrushToolProps) => {

  const handleChangeBrushSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeBrushSize(parseInt(event.target.value));

  };

  const setIndicatorSize = {
    width: brushSize,
    backgroundColor: selectedColor
  }

  return (
    <div className={`brush-size ${className}`}>
      <div className="range">
        <input
          type="range"
          min="5"
          max="40"
          step="5"
          value={brushSize}
          onChange={handleChangeBrushSize}
        />
      </div>
      <div style={setIndicatorSize} className='size-indicator'></div>
    </div>
  );
};
export default BrushTool