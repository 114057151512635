import React, { ReactNode, useEffect, useState } from "react";
import { IconType } from "react-icons";
import { MdOutlineNavigateNext } from "react-icons/md";
import { Category, Subcategory, categories } from "../../types";

import "./CanvasMenubar.css"

interface CanvasMenubarProps {
    onSelectCategory: (category: Category) => void
    onSelectSubcategory: (category: Subcategory) => void
    selectedCategory: Category | null
    selectedSubcategory: Subcategory | null
}

interface IconWrapperProps {
    icon: IconType;
    className?: string;
}

const IconWrapper: React.FC<IconWrapperProps> = ({ icon: Icon, className }) => <Icon className={className} />;

export const CanvasMenubar = ({ onSelectCategory, onSelectSubcategory, selectedCategory, selectedSubcategory }: CanvasMenubarProps) => {

    const [collapsed, setCollapsed] = useState<boolean>(true)

    const handleCollapsed = () => {
        setCollapsed(!collapsed)
    }

    const handleSubcategoryClick= (category: Subcategory) => {
        onSelectSubcategory(category)
    };

    const handleCategoryClick = (category: Category) => {
        onSelectCategory(category);
        onSelectSubcategory(category.subcategories[0])
    };

    useEffect(()=>{
        if(!selectedSubcategory && selectedCategory){
            onSelectSubcategory(selectedCategory.subcategories[0])
        }
    }, [selectedCategory])

    return (
        <div className="canvas-menubar">
           <MdOutlineNavigateNext
                className={`menubar-collapse-btn ${collapsed? "btn-closed" : "btn-open"}`}
                onClick={handleCollapsed} 
            />
            
            <div className={collapsed? "categories-collapsed" : "categories-open"}>
            <div className="divider"></div>
                {categories.map((category, index) => (
                    <div key={index} className={`category ${selectedCategory?.ref === category.ref ? "selected" : ""}`} onClick={() => handleCategoryClick(category)}>
                        <IconWrapper icon={category.logo} className="menubar-icons"/>

                        {collapsed? null: category.name}
                    </div>
                ))}
                <div className="divider"></div>
            </div>
            <div className={collapsed? "subcategories-collapsed" : "subcategories-open"}>
                <div className="divider"></div>

                {selectedCategory && selectedCategory.subcategories.map((subcategory, index) => (
                    <div key={index} className={`subcategory ${selectedSubcategory?.ref === subcategory.ref ? "selected" : ""}`} onClick={() => handleSubcategoryClick(subcategory)}>
                        {subcategory.name}
                    </div>
                ))}
                <div className="divider"></div>
            </div>
        </div>
        
    );
};

export default CanvasMenubar;
