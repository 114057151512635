import React from 'react';
import './Footer.css'; // Import your CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <p>A hobby service made by Oliver Dragland &lt;3</p>
    </footer>
  );
}

export default Footer;