import React, { useState } from 'react';

import axios from 'axios';

import "../login/Login.css"
import { useNavigate } from 'react-router-dom';

import { getCsrfToken } from '../../../App';

export const Register = () => {
    const [username, setUsername] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    const navigate = useNavigate()

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault(); 

        try {
            const csrfToken = await getCsrfToken();
    
            if (!csrfToken) {
                setErrorMessage("Ouch! Something went wrong :( Try again later.")
                console.error('CSRF token not available');
                return;
            }
        
            // Include CSRF token in headers
            const headers = {
                'X-CSRFToken': csrfToken
            };

            
            const response = await axios.post('/auth/register', { username, email, password }, {
                withCredentials: true,
                headers
            });

            if (!response.status) {
                const errorData = await response.data;
                setErrorMessage(errorData.error);
            } else {
                sessionStorage.setItem("loggedIn", "true")
                sessionStorage.setItem("kittelsen_access", response.data.kittelsen_access)
                sessionStorage.setItem("username", response.data.username)
                sessionStorage.setItem("user_id", response.data.id)
                setErrorMessage('');
                navigate("/")
            }
        } catch (error) {
            console.error('Error occurred while registering:', error);
            setErrorMessage('An unexpected error occurred.');
        }
    };

    return (
        <div className='login-container'>
            {errorMessage && <p>Error: {errorMessage}</p>}
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={username}
                    className='form-input'
                    placeholder='Choose username'
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="text"
                    value={email}
                    className='form-input'
                    placeholder='Enter email'
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input type="password"
                    value={password}
                    className='form-input'
                    placeholder='Choose password'
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button className="form-button"type="submit">Register</button>
            </form>
        </div>
    );
};

export default Register;