import React, { useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';

import "./ColorPicker.css"

interface ColorPickerProps {
    onChangeColor: (color: string) => void;
    useHistory?: boolean;
    className?: string
}


const ColorPicker = ({onChangeColor, useHistory, className}: ColorPickerProps) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [selectedColor, setSelectedColor] = useState<ColorResult>();

    const [history, setHistory] = useState<ColorResult[]>([])

    interface ColorIconProps {
        color: ColorResult;
        onClick: () => void
        className?: string
    }
    interface SelectColorIcon {
        color?: ColorResult
        onClick: () => void
        className?: string
    }
    const ColorIcon = ({color, onClick, className}: ColorIconProps) => {

        const setcolor = {
            backgroundColor: color.hex
        }
    
        return (
            <div style={setcolor} onClick={onClick} className={className}>
    
            </div>
        )
    }
    
    
    const SelectColorIcon = ({color, onClick, className}: SelectColorIcon) => {
    
        const setcolor = {
            backgroundColor: color?.hex,
        }
    
        return (
            <div onClick={onClick} className={className}>
                <div style={setcolor}></div>
            </div>
        )
    }
    const handleClick = () => {
        console.log("opening")
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (color: ColorResult) => {
        setSelectedColor(color);
        if (useHistory) {
            // Add the new color to history
            setHistory(prevHistory => {
                // If history length exceeds 20, remove the first element
                if (prevHistory.length >= 19) {
                    prevHistory.shift(); // Remove the first element
                }
                return [...prevHistory, color];
            });
        }
        onChangeColor(color.hex);
    };

    const popover = {
        position: 'absolute' as 'absolute', // Explicitly specify the type
        zIndex: '2' as '2', // Explicitly specify the type
    };

    const cover = {
        position: 'fixed' as 'fixed',
        top: '0px' as '0px',
        right: '0px' as '0px',
        bottom: '0px' as '0px',
        left: '0px' as '0px',
    };

    return (
        
        <div className={`color-picker ${className}`}>
            <SelectColorIcon onClick={handleClick} color={selectedColor} className='select-color-icon'/>
            {displayColorPicker && (
                <div style={popover}>
                    <div style={cover} onClick={handleClose} />
                    <ChromePicker color={selectedColor?.hex} onChange={handleChange} />
                </div>
            )}
            {history.slice().reverse().map((color, index)=>(
                <ColorIcon key={index} onClick={()=>handleChange(color)} color={color} className='color-icon'/>
            ))}
        </div>
    );
};

export default ColorPicker;
