import React, { useState, useEffect } from 'react';
import { Image } from '../../types';
import axios from 'axios';
import "./SavedWork.css"
const SavedWork = () => {
  const [images, setImages] = useState<Image[]>([]);

  useEffect(() => {
    // Fetch metadata for all images
    axios.get<Image[]>('http://localhost:5000/images/all', { withCredentials: true }) // Specify the response type as Image[]
      .then(response => {
        setImages(response.data);
      })
      .catch(error => {
        console.error('Error fetching images:', error);
      });
  }, []); // Empty dependency array ensures useEffect runs only once on component mount

  return (
    <div className='saved-work-wrapper'>
        <p className='saved-work-title'>Your previously generated images</p>
        <div className='image-wrapper'>
        {images.map(image => (
            <img key={image.id} src={`http://localhost:5000/images/uploads/${image.id}`} alt={image.title} />
        ))}
        </div>
    </div>
    
  );
};

export default SavedWork;