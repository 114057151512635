import React, { useState } from 'react';
import BrushTool from './tools/BrushTool/BrushTool';
import ResetTool from './tools/ResetTool/ResetTool';
import ColorPicker from './tools/ColorPicker/ColorPicker';

interface ToolbarProps {
  onChangeBrushSize: (brushSize: number) => void;
  brushSize: number;
  onResetCanvas: () => void;
  onColorChange: (color: string) => void
  className?: string;
}

export const Toolbar = ({ onChangeBrushSize, brushSize, onResetCanvas, onColorChange, className}: ToolbarProps) => {

  return (
    <div className={className}>
    </div>
  );
};

export default Toolbar;
