import React, { useEffect, useState } from 'react';
import { Changelog } from '../../types';
import axios from 'axios';
import "./Changelogpage.css";

export const Changelogpage = ({title}:{title: string}) => {
    const [changelogs, setChangelogs] = useState<Changelog[]>([]);

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        axios.get('/changelog/all')
            .then(response => {
                setChangelogs(response.data);
            })
            .catch(error => {
                console.error('Error fetching changelogs:', error);
            });
    }, []);

    

    return (
        <div className="changelog-wrapper">
            <div className='changelog-title-wrapper'>
                <p className="page-title">Changelog</p>
                <p className="page-subtitle">Updates about the project</p>
            </div>
            <div className="changelog-content">
                {changelogs.map(changelog => (
                    <div key={changelog.id} className="changelog-block">
                        <div className='timeline-dot'></div>
                        <div className="changelog-block-left">
                            <p className="changelog-date">{new Date(changelog.date).toLocaleDateString()}</p>
                            <p className="changelog-title">{changelog.title}</p>
                            <p className="changelog-version">{changelog.version}</p>
                        </div>
                        <div className="changelog-block-right">
                            <p className="changelog-content-text">{changelog.content}</p>
                        </div>
                    </div>
                )).reverse()}
            </div>
            <div className='timeline-extender'>
                <p>Up-n-coming!</p>
            </div>
            <div className='changelog-content additional-info'>
                <div className="changelog-block">
                    <div className='timeline-dot'></div>
                    <div className="changelog-block-left">
                        <p className="changelog-title">Amazon Sagemaker!</p>
                        <p className="changelog-version">v1.1.3</p>
                    </div>
                    <div className="changelog-block-right">
                        <p className="changelog-content-text">To return back to really fast generation times, I've been thinking about deploying the LCM on Amazon Sagemaker as a microservice. This will result in lightning fast on demand image generation.</p>
                    </div>
                </div>
                <div className="changelog-block">
                    <div className='timeline-dot'></div>
                    <div className="changelog-block-left">
                        <p className="changelog-title">Kinde AUTH</p>
                        <p className="changelog-version">v1.1.4</p>
                    </div>
                    <div className="changelog-block-right">
                        <p className="changelog-content-text">To get a better user experience regarding the authentication part of the application, I've been wanting to utilize Kinde Auth, but have yet to find the time. This will serve as my app's backbone in regards to SSO, OAuth2 and OpenID.</p>
                    </div>
                </div>
                <div className="changelog-block">
                    <div className='timeline-dot'></div>
                    <div className="changelog-block-left">
                        <p className="changelog-title">Freedraw sketch to render</p>
                        <p className="changelog-version">v1.2.0</p>
                    </div>
                    <div className="changelog-block-right">
                        <p className="changelog-content-text">I'm currently working on a new Kittelsen mode called Freedraw sketch to render. This is like freedraw, however you only get to draw using black lines as you would sketching a two or one point perspective, however using LCM LoRA and Controlnet for interference, I can bring the real time render capabilities over to architectural sketching.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Changelogpage;
