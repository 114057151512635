import { useEffect, useState} from "react";
import React from "react"
import Canvas from "../Canvas/Canvas";
import { getCsrfToken } from "../../App";
import axios from "axios";
import { MdOutlineClose } from "react-icons/md";
import { zoomies } from 'ldrs';

import "./FreeDrawing.css"

export const FreeDrawing = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [generatedImage, setGeneratedImage] = useState<string | null>(null);
    const [errorMsg, setErrorMsg] = useState<string>("")
    const [prompt, setPrompt] = useState<string>("");
    const [strength, setStrength] = useState<number>(0.8)


    const handleStrengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const strength = parseFloat(event.target.value)
        if(strength > 1){
            setStrength(1)
        }else if(strength < 0){
            setStrength(0)
        }else{
            setStrength(strength)
        }
    }
    const handlePromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrompt(event.target.value)
        console.log(prompt)
    }

    const handleSubmit = async (imageURL: string) => {
        setLoading(true);
        try {
            const csrfToken = await getCsrfToken();
    
            if (!csrfToken) {
                console.error('CSRF token not available');
                setLoading(false);
                return;
            }
            
            // Include CSRF token in headers
            const headers = {
                'X-CSRFToken': csrfToken
            };
    
            const response = await axios.post("/generate/free_draw", {
                prompt: prompt,
                strength: strength,
                image: imageURL
            }, {
                withCredentials: true,
                headers,
                responseType: 'blob'
            });
            console.log("DIOUEH")
            const blob = new Blob([response.data], { type: 'image/png' });
            const blobUrl = URL.createObjectURL(blob);
            setGeneratedImage(blobUrl);
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                // Now TypeScript knows this is an AxiosError, and you can access typical Axios error properties
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 503) {
                        setErrorMsg('This endpoint is currently undergoing maintenance as it\'s being transitioned over to Amazon Sagemaker');
                    } else {
                        setErrorMsg('An unexpected error occurred. Please try again.');
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                    setErrorMsg('No response from the server. Please check your network connection.');
                } else {
                    // Something happened in setting up the request that triggered an error
                    setErrorMsg('Error in sending request.');
                }
            } else {
                // Handle non-Axios errors here
                console.error('An unexpected error occurred:', error);
                setErrorMsg('An unexpected error occurred. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const clearPrompt = () => {
        setPrompt("")
    }

    zoomies.register()

    return (
        <>
            <p className="page-title">Kittelsen Beta v1.1.0</p>
            <div className="free_draw_container">
                <div className="canvas_wrapper">
                    <Canvas
                        width={512}
                        height={512}
                        onGenerate={handleSubmit}
                        updatePrompt={handlePromptChange}
                        updateStrength={handleStrengthChange}
                    />
                    <div className="free_draw_result">
                        <div className={`loading-bar ${loading ? '' : 'loading-bar-hidden'}`}>
                            <l-zoomies
                                size="500"
                                stroke="5"
                                bg-opacity="0.1"
                                speed="1.4"
                                color="gray" 
                            ></l-zoomies>
                        </div>
                        {generatedImage ? (
                            <img src={generatedImage} alt="Generated Image" width={512} height={512}/>
                        ) : errorMsg ? (
                            <div className="construction-box"><p>{errorMsg}</p></div> // Display the error message if there is an error
                        ) : (
                            <p>Start drawing on the canvas</p> // Default message when no image or error is present
                        )}
                    </div>
                </div>
                <div className="prompt-bar">
                    <input
                        id="prompt"
                        placeholder='Write prompt'
                        value={prompt}
                        onChange={handlePromptChange}
                    />
                    <MdOutlineClose onClick={clearPrompt} className="clear-prompt"/>
                    <div className="strength_input">
                        <label htmlFor="strength" className="input-label">Creativity: </label>
                        <div className="range">
                            <input
                                type="range" 
                                id="strength" 
                                min="0.1"
                                max="1"
                                step="0.1"
                                value={strength.toString()}
                                onChange={handleStrengthChange}
                            />
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </>
        
    )
}