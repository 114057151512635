import React, { ReactNode } from "react"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Category, Subcategory, categories } from "../../types"

import "./kittelsenpage.css"
import { FreeDrawing } from "../../components/CanvasModes/FreeDrawing"
import { CanvasMenubar } from "../../components/canvasMenubar/CanvasMenubar"
import SavedWork from "../../components/CanvasModes/SavedWork"

export const Kittelsenpage = ({title}:{title: string}) => {

    const isLoggedIn = sessionStorage.getItem("loggedIn")
    const hasAccess = sessionStorage.getItem("kittelsen_access")
    const navigate = useNavigate()
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(categories[0]);
    const [selectedSubcategory, setSelectedSubcategory] = useState<Subcategory | null>(categories[0].subcategories[0]);

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/auth');
        }
    }, [isLoggedIn, navigate]);
    
    useEffect(() => {
        document.title = "Kittelsen";
    }, []);


    const handleToChangelog = () => {
        console.log("hadebra")
        navigate("/changelog")
    }

    const handleCategorySelect = (category: Category) => {
        setSelectedCategory(category);
    };

    const handleSubcategorySelect = (subcategory: Subcategory) => {
        setSelectedSubcategory(subcategory);
    };


    const renderComponentByCategory = () => {
        console.log(hasAccess)
        switch (selectedSubcategory?.ref) {
            case "free_drawing":
                return hasAccess=="true" ? <FreeDrawing /> : <div className="info-box"><p>You don't have access to this tool. Request access here</p></div>;
            
            case "semantic_drawing":
                return hasAccess=="true" ? <div className="construction-box"><p>This tool is currently under development. Go to
                                                <span onClick={(e)=>handleToChangelog()} className="to-somewhere"> Changelog </span>
                                            for updates.</p></div> : <div className="info-box"><p>You don't have access to this tool. Request access here</p></div>;            
            case "sketch_to_render":
                return hasAccess=="true" ? <div className="info-box"><p>You don't have access to this tool. Request access here</p></div> : <div className="info-box"><p>You don't have access to this tool. Request access here</p></div>;
            
            case "free_drawing_architecture":
                return hasAccess=="true" ? <div className="construction-box"><p>This tool is currently under development. Go to
                                                <span onClick={(e)=>handleToChangelog()} className="to-somewhere"> Changelog </span>
                                            for updates.</p></div> : <div className="info-box"><p>You don't have access to this tool. Request access here</p></div>;     

            case "semantic_drawing_architecture":
                return hasAccess=="true" ? <div className="construction-box"><p>This tool is currently under development. Go to
                                                <span onClick={(e)=>handleToChangelog()} className="to-somewhere"> Changelog </span>
                                            for updates.</p></div> : <div className="info-box"><p>You don't have access to this tool. Request access here</p></div>;            
        }

        switch(selectedCategory?.ref) {
            case "documents":
                return isLoggedIn=="true" ? <SavedWork /> : null
        }
    };

    //

    return (
        <div className="kittelsen">
            <CanvasMenubar 
                onSelectCategory={handleCategorySelect} 
                onSelectSubcategory={handleSubcategorySelect}
                selectedCategory={selectedCategory}
                selectedSubcategory={selectedSubcategory}
            />
            <div className="kittelsen_content">
                {renderComponentByCategory()}
            </div>
        </div>
    )
}
export default Kittelsenpage