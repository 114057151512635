import React, { FormEvent, HtmlHTMLAttributes, useEffect, useRef, useState } from "react"
import axios from "axios";
import './Landingpage.css';
import { FaPaintBrush } from "react-icons/fa";
import { MdNavigateNext, MdArchitecture } from "react-icons/md";
import gsap from "gsap";
import { useNavigate } from "react-router-dom";


export const Landingpage = ({title}:{title: string}) => {

  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const navigate = useNavigate()
  const [isCardsVisible, setCardsVisible] = useState<boolean>(false)

  useEffect(() => {
      document.title = title;
  }, [title]);

  useEffect(()=>{
    gsap.from('.image-holder', {
      duration: 1,
      opacity: 0,
      scale: 0,
      ease: 'power3.out',
    })
  }, [])
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          gsap.to('.card', {
            duration: 1,
            opacity: 1,
            scale: 1,
            stagger: 0.2,
            ease: 'power3.out',
          })
          gsap.to('.card2', {
            delay: 0.21,
            duration: 1,
            opacity: 1,
            scale: 1.2,
            ease: 'power3.out',
          })
          observer.unobserve(entry.target); // Stop observing after animation is triggered
        }
      });
    }, { threshold: 0.7 }); // Adjust threshold as needed

    cardRefs.current.forEach((cardRef) => {
      if (cardRef) {
        observer.observe(cardRef);
      }
    });

    return () => {
      observer.disconnect(); // Cleanup observer when component unmounts
    };
  }, []);

  const handleToKittlesen = () => {
    navigate("/kittelsen")
  }
  return (
    <>
       <div className="hero">
      <p className="hero-title-content">KITTELSEN</p>
      <p className="hero-subtitle-content">Express your inner artist using AI</p>
      {/*<button className="to-kittelsen-btn" onClick={handleToKittlesen}>Try it out <MdNavigateNext /></button>*/}

      
      <div className="container">
            <div className="circle-1 circles">
              <div className="tail tail-1"></div>
              <FaPaintBrush className="head head-1"/>
            </div>
            <div className="circle-2 circles">
              <div className="tail"></div>
              <FaPaintBrush className="head head-2"/>
            </div>
            <div className="circle-3 circles">
              <div className="tail"></div>
              <FaPaintBrush className="head head-3"/>
            </div>
            <div className="circle-4 circles">
              <div className="tail"></div>
              <FaPaintBrush className="head head-4"/>
            </div>

      </div>
      <div className="image-holder"></div>
      
      <div className="card-holder">
        <div className="card" ref={el => (cardRefs.current[0] = el)}>
          <MdArchitecture className="card-header"/>
          <p className="card-title">Visualize</p>
          <p className="card-info">Turn architectural sketches and model images into realistic sketches in seconds.</p>
        </div>
        <div className="card card2" ref={el => (cardRefs.current[0] = el)}>
          <p className="card-header">~1.0s</p>
          <p className="card-title">Render time</p>
          <p className="card-info">By utilizing LCM technology, our open canvas reaches astonishing speeds!</p>
        </div>
        <div className="card" ref={el => (cardRefs.current[0] = el)}>
          <FaPaintBrush className="card-header"/>
          <p className="card-title">Draw</p>
          <p className="card-info">Let AI be an extension to your creativeness. Draw anything you want on an open canvas.</p>
        </div>
      </div>
    </div>
    </>
   

  )
}

export default Landingpage;