import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdLogin, MdLogout } from "react-icons/md"
import { getCsrfToken } from '../../App';
import './Navbar.css'; // Import your CSS file for styling
import axios from 'axios';
const Navbar = () => {

  const location = useLocation()
  const [isLoggedIn, setIsLoggedIn] = useState<string | null>(sessionStorage.getItem('loggedIn'))
  const navigate = useNavigate();
  
  
  // Function to handle logout
  const handleLogout = async () => {
    sessionStorage.removeItem('loggedIn');
    sessionStorage.removeItem("kittelsen_access")
    setIsLoggedIn(null);
  
    try {
      // Get CSRF token
      const csrfToken = await getCsrfToken();
  
      if (!csrfToken) {
        console.error('CSRF token not available');
        return;
      }
  
      // Include CSRF token in headers
      const headers = {
        'X-CSRFToken': csrfToken
      };
  
      // Make POST request with CSRF token in headers
      const response = await axios.get('/auth/logout', {
        headers,
        withCredentials: true // Include credentials in the request
      });
  
      if (!response.status) {
        console.error(response.data);
      }else{
        navigate("/")
      }
    } catch (error) {
      console.error('Error occurred while logging out:', error);
    }
  }

  const handleLogin = () => {
    navigate("/auth")
  }

  useEffect(()=>{

  }, [isLoggedIn])

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-logo">Beyourownartist.ai</Link>
      <div className="navbar-menu">
        <Link to="/" className={location.pathname === '/' ? 'active_path' : ''}>Home</Link>
        <Link to="/kittelsen" className={location.pathname === '/kittelsen' ? 'active_path' : ''}>Kittelsen</Link>
        <Link to="/about" className={location.pathname === '/about' ? 'active_path' : ''}>About</Link>
        <Link to="/changelog" className={location.pathname === '/changelog' ? 'active_path' : ''}>Changelog</Link>
        {isLoggedIn ? (
              <div className='logged-in-info'>
                  <p>{sessionStorage.getItem('username')}#{sessionStorage.getItem('user_id')}</p>
                  <div className="navbar-log-btn" onClick={handleLogout}><MdLogin /><p>Logout</p></div>
              </div>
          ) : (
              <div className="navbar-log-btn" onClick={handleLogin}><MdLogin /><p>Login</p></div>
          )
        }
      </div>
    </nav>
  );
}

export default Navbar;