import React, { useRef, useEffect, useState } from 'react';
import Toolbar from '../Toolbar/Toolbar';
import BrushTool from '../Toolbar/tools/BrushTool/BrushTool';
import ResetTool from '../Toolbar/tools/ResetTool/ResetTool';
import ColorPicker from '../Toolbar/tools/ColorPicker/ColorPicker';

import "./Canvas.css"

interface CanvasProps {
  width: number;
  height: number;
  onGenerate: (imageURL: string) => void
  updatePrompt: (event: React.ChangeEvent<HTMLInputElement>) => void
  updateStrength: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Canvas = ({ width, height, onGenerate, updatePrompt, updateStrength}: CanvasProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [brushSize, setBrushSize] = useState(5);
    const [backgroundStatus, setBackgroundStatus] = useState<boolean>(false)
    const [brushColor, setBrushColor] = useState<string>('#000000');

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const context = canvas.getContext('2d');
        if (!context) return;

        // Set background color to white
        if(!backgroundStatus){
            context.fillStyle = '#ffffff';
            context.fillRect(0, 0, canvas.width, canvas.height);
            setBackgroundStatus(true)
        }


        context.lineCap = 'round';
        context.strokeStyle = brushColor;
        context.lineWidth = brushSize;

        const handleMouseDown = (event: MouseEvent) => {
            const { offsetX, offsetY } = event;
            context.beginPath();
            context.moveTo(offsetX, offsetY);
            setIsDrawing(true);
        };

        const handleMouseMove = (event: MouseEvent) => {
            if (!isDrawing) return;
            const { offsetX, offsetY } = event;
            context.lineTo(offsetX, offsetY);
            context.stroke();
        };

        const handleMouseUp = () => {
            setIsDrawing(false);

            const canvas = canvasRef.current
            if(!canvas) return
            const imageUrl = canvas.toDataURL()

            onGenerate(imageUrl)
        }

        const handleMouseLeave = () => {
            setIsDrawing(false);
        };

        const handleMouseEnter = () => {
            if (isDrawing) setIsDrawing(true);
        };

        canvas.addEventListener('mousedown', handleMouseDown);
        canvas.addEventListener('mousemove', handleMouseMove);
        canvas.addEventListener('mouseup', handleMouseUp);
        canvas.addEventListener('mouseleave', handleMouseLeave);
        canvas.addEventListener('mouseenter', handleMouseEnter);

        return () => {
            canvas.removeEventListener('mousedown', handleMouseDown);
            canvas.removeEventListener('mousemove', handleMouseMove);
            canvas.removeEventListener('mouseup', handleMouseUp);
            canvas.removeEventListener('mouseleave', handleMouseLeave);
            canvas.removeEventListener('mouseenter', handleMouseEnter);
        };
    }, [isDrawing, brushSize, brushColor]);

    const handleChangeBrushSize = (size: number) => {
        setBrushSize(size);
    };

    const handleResetCanvas = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;
    
        const context = canvas.getContext('2d');
        if (!context) return;
    
        // Clear the canvas by filling it with white
        context.fillStyle = '#ffffff';
        context.fillRect(0, 0, canvas.width, canvas.height);
    };

    const handleColorChange = (color: string) => {
        setBrushColor(color)
    }

    return (
        <div className='free_draw_canvas'>
            <canvas ref={canvasRef} width={width} height={height} className='canvas'/>
            <ColorPicker onChangeColor={handleColorChange} useHistory={true} className='color-picker-position'/>
            <BrushTool onChangeBrushSize={handleChangeBrushSize} selectedColor={brushColor} brushSize={brushSize} className='brush-size-position'/>
            <ResetTool onReset={handleResetCanvas} className='reset-tool-position'/>
            <Toolbar 
                onChangeBrushSize={handleChangeBrushSize}
                brushSize={brushSize}
                onResetCanvas={handleResetCanvas}
                onColorChange={handleColorChange}
                className='toolbar'
            />
        </div>
    );
};

export default Canvas;
