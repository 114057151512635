import React, { ReactEventHandler, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "./Login.css";

import { getCsrfToken } from '../../../App';

export const Login = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const navigate = useNavigate();


    const handleInputChange = (field: string, value: string) => {
        if(field==="username"){
            setUsername(value);
        } else if(field==="password"){
            setPassword(value);
        }
        setErrorMessage('');
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();


        try {
            const csrfToken = await getCsrfToken();
    
            if (!csrfToken) {
                setErrorMessage("Ouch! Something went wrong :( Try again later.")
                console.error('CSRF token not available');
                return;
            }
        
            // Include CSRF token in headers
            const headers = {
            'X-CSRFToken': csrfToken
            };
        
        
            axios.post('/auth/login', { username, password }, {
                withCredentials: true,
                headers
            })
            .then(response => {
                setErrorMessage('');
                sessionStorage.setItem('loggedIn', 'true');
                sessionStorage.setItem("kittelsen_access", response.data.kittelsen_access)
                sessionStorage.setItem("username", response.data.username)
                sessionStorage.setItem("user_id", response.data.id)
                navigate("/");
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    const errorData = error.response.data;
                    setErrorMessage(errorData.error);
                } else {
                    console.error('Error:', error);
                    setErrorMessage(error + ': An unexpected error occurred. Please try again later.');
                }
            });
        } catch (error) {
            console.error('Error occurred while logging in:', error);
            setErrorMessage('An unexpected error occurred.');
        }
    };

    return (
        <div className='login-container'>
            {errorMessage && <div className='error-box'><p>{errorMessage}</p></div>}
            <form onSubmit={handleSubmit}>
                <input 
                    type="text"
                    value={username}
                    className='form-input'
                    placeholder='Enter username'
                    onChange={(e) => handleInputChange("username", e.target.value)}
                />
                <input 
                    type="password"
                    value={password}
                    className='form-input'
                    placeholder='Enter password'
                    onChange={(e) => handleInputChange("password" ,e.target.value)}
                />
                <button type="submit" className='form-button'>Login</button>
            </form>
        </div>
    );
};

export default Login;
