import React from 'react';
import { RiDeleteBin6Line } from "react-icons/ri";

import "./ResetTool.css"

interface ResetToolProps {
  onReset: () => void;
  className?: string
}

export const ResetTool = ({ onReset, className}: ResetToolProps) => {
  return (
    <RiDeleteBin6Line onClick={onReset} className={`reset-tool ${className}`}/>
  );
};

export default ResetTool;